export const environment = {
  production: false,
  isLocalDev: false,
  oneTrustDomainId: 'dc6a175e-98b5-41ac-a2fe-873bf94dcf8b',
  oneTrustCookieDomains: ['.acadvantage.acacceptance.com', '.acacceptance.com'],
  oneTrustTestMode: true,
  fullStoryEnabled: false,
  fullStoryOrgId: '125F9D',
  authUrl: 'https://acadvantage.dev.acacceptance.com/auth',
  sharedApiUrl: 'https://acadvantage-shared-api.dev.acacceptance.com/api/v1.0',
  rehashApiUrl: 'https://acadvantage-rehash-api.dev.acacceptance.com/api/v1.0',
  preverificationApiUrl: 'https://preverificationapi.svc.dev.acacceptance.com',
  authApiUrl: 'https://acadvantageauth.dev.svc.acacceptance.com',
  dreamsApiUrl: 'https://dreams.svc.dev.acacceptance.com/api/v1.0',
  signInUrl:
    'https://acadvantage-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=7ehi4c5vb46dn02tdedsl7ap2h&redirect_uri=https://acadvantage.dev.acacceptance.com/auth',
  defaultInternalUserDealerNo: 28635,
  remotes: {
    preverification: 'https://acadvantage-preverification.dev.acacceptance.com',
    rehash: 'https://acadvantage-rehash.dev.acacceptance.com',
    dreams: 'https://acadvantage-dreams.dev.acacceptance.com',
    dashboard: 'https://acadvantage-dashboard.dev.acacceptance.com'
  }
};
